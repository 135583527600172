<template>
    <vs-row vs-justify="center">
        <vs-col type="flex" vs-justify="center" vs-align="center" vs-lg="12" vs-xs="12">
            <vs-card>
                <vs-row vs-justify="center">
                    <vs-col vs-lg="2">
                        <h3>Trabajos</h3>
                    </vs-col>
                    <vs-col vs-lg="3">
                        <vs-select autocomplete label="Categoria" v-model="categoria">
                            <vs-select-item :value="0" text="Todas" />
                            <vs-select-item :key="index" :value="item.id" :text="item.name" v-for="(item,index) in categorias" />
                        </vs-select>
                    </vs-col>
                    <vs-col vs-lg="3">
                        <vs-select autocomplete label="Edicion" v-model="edicion">
                            <vs-select-item :value="0" text="Todas" />
                            <vs-select-item :key="index" :value="item.edition" :text="item.edition" v-for="(item,index) in years" />
                        </vs-select>
                    </vs-col>
                    <vs-col vs-lg="3">
                        <vs-select autocomplete label="Profesional" v-model="profesional">
                            <vs-select-item :value="-1" text="Todos" />
                            <vs-select-item :value="1" text="SI" />
                            <vs-select-item :value="0" text="NO" />
                        </vs-select>
                    </vs-col>
                    <vs-col  vs-lg="1" vs-align="flex-end">
                        <vs-button @click="nuevo" color="success" type="relief">Agregar Nuevo</vs-button>
                    </vs-col>
                </vs-row>
            </vs-card>

            <vs-card>
                <vs-row vs-justify="center">
                    <vs-col vs-lg="12">

                            <div slot="header">
                                <div class="d-flex align-items-center">
                                <div>
                                    <h5 class="card-title">Participantes</h5>
                                    <h6 class="card-subtitle mb-0">Todos los participantes, ya sean de seccion especial o de concurso</h6>
                                </div>
                                </div>
                            </div>
                            <VityTable
                                v-if="url"
                                :columns="columns"
                                :url="url+'works'"
                                :params="params"
                                :orderBy="{
                                    column: 0,
                                    type: 1
                                }"
                                ref="table"
                            />

                    </vs-col>
                </vs-row>
            </vs-card>

            <vs-card v-if="trabajo">
                <h4 class="card-title d-flex">
                    Trabajo {{trabajo.title}} ({{trabajo.edition}}) <label v-if="trabajo.votes && !trabajo.category.professional"> => Votado {{trabajo.votes}} veces</label>
                </h4>
                <p class="card-subtitle">
                    Aqui podras cambiar toda la informacion del trabajo selecionado
                </p>

                <vs-row vs-justify="center">
                    <vs-col vs-lg="2" vs-xs="2">
                        <label for="" class="mr-2 mb-0">Activo</label>
                        <vs-switch color="success" v-model="trabajo.active"/>
                    </vs-col>
                    <vs-col vs-lg="4" vs-xs="12">
                        <vs-input maxlength="255" label="Titulo" class="inputx" placeholder="Titulo" v-model="trabajo.title"/>
                    </vs-col>
                    <vs-col vs-lg="4" vs-xs="12">
                        <vs-select autocomplete label="Participante" v-model="trabajo.participant_id">
                            <vs-select-item :key="index" :value="item.id" :text="item.name + ' ' + item.lastname" v-for="(item,index) in participantes" />
                        </vs-select>
                    </vs-col>
                    <vs-col vs-lg="12" vs-xs="12">
                        <br>
                        <label>Descripcion</label>
                        <vs-textarea rows="3" counter="2000" label="Counter: 2000" :counter-danger.sync="descripcionDanger" v-model="trabajo.description" />
                    </vs-col>
                    <vs-col vs-lg="4" vs-xs="12">
                        <label>Imagen</label>
                        <input type="file" @change="cambiarFoto" />
                    </vs-col>
                    <vs-col vs-lg="4" vs-xs="12" style="max-height: 198px">
                        <img style="display: block;min-width: 100%;width: 100%;min-height: 100%;height: 100%;" v-show="trabajo.img" :src="trabajo.img"/>
                    </vs-col>
                    <vs-col vs-lg="4" vs-xs="12">
                        <vs-select autocomplete label="Categoria" v-model="trabajo.category_id">
                            <vs-select-item :key="index" :value="item.id" :text="item.name" v-for="(item,index) in categorias" />
                        </vs-select>
                    </vs-col>
                    <vs-col vs-lg="4" vs-xs="12">
                        <vs-select autocomplete label="Edicion" v-model="trabajo.edition">
                            <vs-select-item :key="index" :value="item.edition" :text="item.edition" v-for="(item,index) in years" />
                        </vs-select>
                    </vs-col>
                    <vs-col vs-lg="4" vs-xs="12">
                        <vs-input label="Creado el" class="inputx" placeholder="Creado el" :value="$parent.$parent.formatDate('d/m/Y H:i:s', trabajo.created_at, true)" disabled/>
                    </vs-col>
                    <vs-col vs-lg="4" vs-xs="12">
                        <vs-input label="Actualizado el" class="inputx" placeholder="Actualizado el" :value="$parent.$parent.formatDate('d/m/Y H:i:s', trabajo.updated_at, true)" disabled/>
                    </vs-col>
                    <vs-col vs-lg="4" vs-xs="12">
                        <label>Activado el</label><br>
                        <v-date-picker v-model="trabajo.actived_at" mode="dateTime" is24hr>
                            <template v-slot="{ inputValue, inputEvents }">
                                <input
                                class="px-2 py-1 border focus:outline-none focus:border-blue-300"
                                :value="inputValue"
                                v-on="inputEvents"
                                />
                            </template>
                        </v-date-picker>
                    </vs-col>
                </vs-row>
                <vs-row>
                    <vs-col vs-lg="12" vs-xs="12">
                        <vs-button @click="save" color="success" type="relief">Guardar</vs-button>
                    </vs-col>
                </vs-row>
            </vs-card>
            <vs-card v-if="trabajo">
                <h4 class="card-title d-flex">
                    Contenido
                </h4>
                <vs-row v-if="![3,4,2,7].includes(trabajo.category_id)">
                    <vs-col vs-lg="12" vs-xs="12">
                        <input type="file" @change="cambiarFile" />
                    </vs-col>
                </vs-row>
                <vs-row vs-justify="center">
                    <vs-col vs-lg="12" vs-xs="12" v-if="[2,7].includes(trabajo.category_id)">
                        <img v-show="trabajo.file" :src="trabajo.file" style="max-width: 100%"/>
                    </vs-col>
                    <vs-col vs-lg="12" vs-xs="12" v-if="[5,6,11].includes(trabajo.category_id)">
                        <audio controls v-show="trabajo.file">
                            <source :src="trabajo.file+'.mp3'"/>
                            <source :src="trabajo.file+'.ogg'"/>
                        </audio>
                    </vs-col>
                    <vs-col vs-lg="12" vs-xs="12" v-if="[1,8,12,13].includes(trabajo.category_id)">
                        <video controls v-show="trabajo.file" style="max-width: 100%">
                            <source :src="trabajo.file"/>
                        </video>
                    </vs-col>
                    <vs-col vs-lg="12" vs-xs="12" v-if="[3, 4].includes(trabajo.category_id)">
                        <vue-editor v-model="trabajo.story" />
                    </vs-col>
                </vs-row>
                <vs-row v-if="![3,4].includes(trabajo.category_id) && trabajo.file">
                    <a :href="trabajo.file+([5,6].includes(trabajo.category_id) ? '.mp3' : '')" download>Descargar</a>
                </vs-row>
            </vs-card>
        </vs-col>
    </vs-row>
</template>

<script>
import axios from 'axios'
import VityTable from "@/components/VityTable.vue";
import {VueEditor} from 'vue2-editor'

export default {
    name: 'Trabajos',
    components: {
        VityTable,
        VueEditor,
    },
    data: function(){
        return{
            columns:[
                {
                    name: 'ID',
                    dbName: 'id',
                    orderable: true,
                    searchable: true
                },
                {
                    name: 'Titulo',
                    dbName: 'title',
                    orderable: true,
                    searchable: true
                },
                {
                    name: 'Categoria',
                    dbName: 'categoryName',
                    orderable: true,
                    searchable: false
                },
                {
                    name: 'Edicion',
                    dbName: 'edition',
                    orderable: true,
                    searchable: true
                },
                {
                    name: 'Votos',
                    dbName: 'votes',
                    orderable: true,
                    searchable: false
                },
                {
                    name: 'Accion',
                    dbName: '',
                    orderable: false,
                    searchable: false,
                    template: '<a href="/trabajos/TPLData">EDITAR</a>',
                    reference: 'id'
                },
            ],
            url: process.env.VUE_APP_URL,
            trabajo: null,
            descripcionDanger: false,
            required: [
                {
                    name: 'participant_id',
                    viewName: 'Participante'
                },
                {
                    name: 'title',
                    viewName: 'Titulo'
                },
                {
                    name: 'category_id',
                    viewName: 'Categoria'
                },
                {
                    name: 'edition',
                    viewName: 'Edicion'
                },
            ],
            params:{
                admin: 1,
                category: null,
                edition: null,
                professional: null
            },
            nuevaImagen: null,
            nuevoFile: null,
            participantes: [],
            categorias: [],
            years: [],
            categoria: 0,
            edicion: 0,
            profesional: -1
        }
    },
    methods:{
        getObra: function(id){
            if(!id)
                this.participant = null;
            else{
                axios
                    .get(process.env.VUE_APP_URL+'works/'+id, {
                        params:{
                            admin: 1
                        }
                    })
                    .then(response => {
                        let rs = response.data;
                        if(rs.state == 'OK'){
                            this.trabajo = rs.data;
                            this.trabajo.active = this.trabajo.active ? true : false;
                        }
                        else{
                            this.trabajo = null;
                            this.$parent.$parent.errors = ['Trabajo no encontrado'];
                        }
                    })
                    .catch(error => {
                        this.$parent.$parent.errors = ['Fallo al conectar'];
                    });
            }
        },
        getParticipantes: function(){
            axios
                .get(process.env.VUE_APP_URL+'participants')
                .then(response => {
                    let rs = response.data;
                    if(rs.state == 'OK')
                        this.participantes = rs.data;
                });
        },
        getCategorias: function(){
            axios
                .get(process.env.VUE_APP_URL+'categories', {
                    params: {
                        all: 1,
                        extra: 0
                    }
                })
                .then(response => {
                    let rs = response.data;
                    if(rs.state == 'OK')
                        this.categorias = rs.data;
                });
        },
        save: function(){
            // if(this.$parent.$parent.checkParams(this.required, this.trabajo))
            //     return;

            var params = new FormData();
            params.append('active', this.trabajo.active ? 1 : 0);
            params.append('title', this.trabajo.title);
            params.append('participant_id', this.trabajo.participant_id);
            params.append('category_id', this.trabajo.category_id);
            params.append('edition', this.trabajo.edition);
            if(this.trabajo.actived_at)
                params.append('actived_at', this.$parent.$parent.formatDate('Y-m-d H:i:s', this.trabajo.actived_at));
            if(this.trabajo.description)
                params.append('description', this.trabajo.description);
            if(this.trabajo.story)
                params.append('story', this.trabajo.story);
            if(this.nuevaImagen)
                params.append('img', this.nuevaImagen);
            if(this.nuevoFile)
                params.append('file', this.nuevoFile);

            this.$vs.loading();
            if(this.trabajo.id){
                axios
                    .post(process.env.VUE_APP_URL+'works/'+this.trabajo.id, params)
                    .then(response => {
                        let rs = response.data;
                        if(rs.state == 'OK'){
                            this.getObra(this.$route.params.id);
                            //this.$router.push('participantes/'+rs.data);
                            this.$parent.$parent.aciertos = ['Guardado correctamente'];
                        }
                        else
                            this.$parent.$parent.errors = [rs.msg];
                    })
                    .catch(error => {
                        let rs = error.response.data;
                        if(rs && Object.keys(rs.errors).length > 0){
                            let msg = "";
                            Object.keys(rs.errors).forEach(key => {
                                msg += rs.errors[key][0] + " ";
                            });
                            this.$parent.$parent.errors = [msg];

                        }else if(rs && rs.message){
                            this.$parent.$parent.errors = [rs.message];
                        }else{
                            this.$parent.$parent.errors = ['Hubo un problema al conectar'];
                        }
                    })
                    .finally(() => this.$vs.loading.close());
            }
            else{
                axios
                    .post(process.env.VUE_APP_URL+'works', params)
                    .then(response => {
                        let rs = response.data;
                        if(rs.state == 'OK')
                            this.$router.push('/trabajos/'+rs.data);
                        else
                            this.$parent.$parent.errors = [rs.msg];
                    })
                    .catch(error => {
                        let rs = error.response.data;
                        if(rs && Object.keys(rs.errors).length > 0){
                            let msg = "";
                            Object.keys(rs.errors).forEach(key => {
                                msg += rs.errors[key][0] + " ";
                            });
                            this.$parent.$parent.errors = [msg];
                        }else if(rs && rs.message){
                            this.$parent.$parent.errors = [rs.message];
                        }else{
                            this.$parent.$parent.errors = ['Hubo un problema al conectar'];
                        }
                    })
                    .finally(() => this.$vs.loading.close());
            }
        },
        nuevo: function(){
            this.$router.push('/trabajos/new');
        },
        cambiarFoto: function(data){
            this.nuevaImagen = data.target.files[0];
        },
        cambiarFile: function(data){
            this.nuevoFile = data.target.files[0];
        },
        getEdiciones: function(){
            axios
                .get(process.env.VUE_APP_URL+'editions', {
                    params:{
                        //
                    }
                })
                .then(response => {
                    let rs = response.data;
                    if(rs.state == 'OK'){
                        this.years = rs.data;
                        this.years.unshift({
                            edition: parseInt(rs.data[0].edition) + 1
                        });
                    }
                    else
                        console.log(rs.msg);
                });
        }
    },
    created: function(){
        if(this.$route.params.id == 'new'){
            this.trabajo = {};
        }
        else{
            this.getObra(this.$route.params.id);
        }
        this.getParticipantes();
        this.getCategorias();
        this.getEdiciones();
	},
	beforeRouteUpdate (to, from, next) {
        if(to.params.id == 'new'){
            this.trabajo = {};
        }
        else{
            this.getObra(to.params.id);
        }
        this.getParticipantes();
        this.getCategorias();
        this.getEdiciones();
		next();
	},
    watch: {
        edicion: function(val){
            this.params.edition = val ? val : null;
            this.$refs.table.getDatos();
        },
        categoria: function(val){
            this.params.category = val ? val : null;
            this.$refs.table.getDatos();
        },
        profesional: function(val){
            this.params.professional = val > 0 ? val : null;
            this.$refs.table.getDatos();
        }
    }
}
</script>